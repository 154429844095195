<template>
  <div class="outerContainer">
    <div class="innerContainer">
      <div class="leftInfo">
        <h2>
          Welcome to the Group Travel Odyssey Supplier Manager.
        </h2>
        <br>
        <p>
          Membership for all tourism suppliers is completely free and gives you access to tour operators planning group travel around the globe.  Maintaining your information in GTO helps operators price and quote their trips to clients quickly and efficiently.  This makes it easier for operators to add you to their group travel packages.
        </p>
        <p>
          If you are already a GTO member, please enter your username and password to access your account.
        </p>
        <p>
          If you aren’t a GTO member it is easy to become one!  Just click “Register” and fill out the information and we will be in touch!
        </p>
        <p>
          If you have any questions please contact our Community Architects Jo-Ann Moltz (<a :href="`mailto:jo-ann@gtonetwork.com`">jo-ann@gtonetwork.com</a>) or Jim Deliman (<a :href="`mailto:jim@gtonetwork.com`">jim@gtonetwork.com</a>).
        </p>
        <p>
          Welcome to the community!
        </p>
      </div>
      <RegisterCard v-if="show === 'register'" @child-data="toLogin" style="margin:10px;" />
      <LoginCard v-if="show === 'login'" @child-data="toRegister" @to-forgot-pw="toForgotPw" style="margin:10px;" />
      <ResetPasswordCard v-if="show === 'forgotPassword'" @child-data="toLogin" style="margin:10px;" />
    </div>
  </div>
</template>

<script>
import RegisterCard from '@/components/RegisterCard.vue';
import LoginCard from '@/components/LoginCard.vue';
import ResetPasswordCard from '@/components/ResetPasswordCard.vue';

export default {
  name: 'Home',
  components: {
    RegisterCard, LoginCard, ResetPasswordCard,
  },
  data() {
    return {
      show: 'login',
    };
  },
  beforeCreate() {
    this.$nextTick(() => {
      this.$store.commit('logOut');
    });
  },
  methods: {
    toForgotPw() {
      this.show = 'forgotPassword';
    },
    toRegister() {
      this.show = 'register';
    },
    toLogin() {
      this.show = 'login';
    },
  },
};
</script>

<style lang="scss" scoped>
  .outerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .innerContainer {
    max-width: 1400px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .leftInfo {
    padding: 20px;
    max-width: 500px;
  }
@media only screen and (max-width: 1200px) {
  .innerContainer {
    flex-direction: column;
  }
}
</style>
